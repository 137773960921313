const iState = {
  addCategory: {},
};
export const addCategoryReducer = (state = iState, action: any) => {
  switch (action.type) {
    case 'ADD_CATEGORY':
      return {
        ...state,
        addCategory: action.payload,
      };

    default:
      return state;
  }
};
