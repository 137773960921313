import axios from 'axios';
import ApiInstance from './interceptor';
import api from '../config/api';
import settings from '../config/settings';

import setting from '../config/settings';

import {
  ResponseBaseType,
  GetFreePikPlanResponse,
  GetMFADetailsResponse,
  VerifyMFATokenRequest,
  VerifyMFATokenResponse,
  GetProfessionalsResponse,
  AddProfessionalRequest,
  RemoveProfessionalRequest,
} from 'src/types';

export const adminLoginService = (params: any): Promise<ResponseBaseType<any>> => ApiInstance.post(`${api.ADMIN_LOGIN}`, params);

export const getMFADetails = (): Promise<ResponseBaseType<GetMFADetailsResponse>> => {
  return ApiInstance.get(`${api.ADMIN_MFA_DETAILS}`);
};

export const verifyMFAToken = (params: VerifyMFATokenRequest): Promise<ResponseBaseType<VerifyMFATokenResponse>> => {
  return ApiInstance.post(`${api.ADMIN_VERIFY_MFA}`, params);
};

export const adminLogOutService = (params: any) => ApiInstance.post(`${api.ADMIN_LOGOUT}`, params);

export const adminForgotPasswordService = (params: any) => ApiInstance.post(`${api.ADMIN_FORGOT_PASSWORD}`, params);

export const adminResetPasswordService = (params: any, token: any) => {
  axios.defaults.headers.common['accessToken'] = `${token}`;
  axios.defaults.headers.common['authorization'] = `${settings.api.AUTH}`;
  return axios.post(api.ADMIN_RESET_PASSWORD, params);
};

export const searchService = (params: any) =>
  ApiInstance.get(
    `${api.GET_ALL_CATEGORIES}?type=${params.type}&page=${params.page}&limit=${params.limit}&searchString=${params.searchString}`,
  );

export const tokenVarificationService = (params: any) => ApiInstance.get(`${api.ADMIN_VARIFY_TOKEN}?token=${params}`);

export const getAllCategoriesService = (params: any) =>
  ApiInstance.get(`${api.GET_ALL_CATEGORIES}?type=${params.type}&page=${params.page}&limit=${params.limit}`);

export const getUserCategoryService = (params: any) => ApiInstance.get(`${api.GET_USER_CATEGORIES}?categoryId=${params}`);

export const deleteCategoryService = (params: any) => ApiInstance.delete(`${api.DELETE_CATEGORY}?categoryId=${params}`);

export const updateCategoryService = (params: any) => ApiInstance.put(`${api.UPDATE_CATEGORY}`, params);

export const addCategoryService = (params: any) => ApiInstance.post(`${api.ADD_CATEGORY}`, params);

export const getCategoryService = (params: any) => ApiInstance.get(`${api.GET_CATEGORY}${params}`);

export const getSubCategoryService = (params: any) => ApiInstance.get(`${api.GET_SUB_CATEGORY}${params}`);

export const getSkillsService = (params: any) => ApiInstance.get(`${api.GET_SKILLS}${params}`);

export const getCmsService = (params: any) => ApiInstance.get(`${api.GET_CMS}`);

export const addCmsService = (params: any) => ApiInstance.post(`${api.ADD_CMS}`, params);

export const updateCmsService = (params: any) => ApiInstance.put(`${api.UPDATE_CMS}`, params);

export const getUsersByRoleService = (params: any) =>
  ApiInstance.get(
    `${api.USERS_BY_ROLE}?role=${params?.role}&page=${params?.page}&limit=${params?.limit}&status=${params?.status}&searchString=${params?.searchString}`,
  );

export const userVerifyDocumentService = (params: any) => ApiInstance.put(`${api.USER_VERIFY_DOCUMENT}`, params);

export const getUserProfileService = (params: any) => ApiInstance.get(`${api.GET_USER_PROFILE}/${params}`);

export const createAdminService = (params: any) => ApiInstance.post(`${api.CREATE_ADMIN}`, params);

export const getAdminService = (params: any) => ApiInstance.get(`${api.GET_ADMIN}/${params}`);

export const deleteAdminService = (params: any) => ApiInstance.put(`${api.DELETE_ADMIN}`, params);

export const updateAdminService = (params: any) => ApiInstance.put(`${api.UPDATE_ADMIN}`, params);

// Project api
export const getAdminProjectList = (params: any) =>
  ApiInstance.get(
    `${api.GET_ADMIN_PROJECT_LIST}?page=${params.page}&limit=${params.limit}&searchString=${params.searchString}&status=${params.status}&category=${params.category}&subCategory=${params.subCategory}&clientId=${params.clientId}`,
  );
// export const getAdminProjectList = params => ApiInstance.post(`${api.GET_ADMIN_PROJECT_LIST}`, params);

export const getUserProjectDetail = (params: any) => ApiInstance.get(`${api.GET_USER_PROJECT_DETAIL}/${params}`);
export const updateProjectStatus = (params: any) =>
  ApiInstance.post(`${api.ADMIN_UPDATE_PROJECT_STATUS}/${params.status}`, params);

export const accountStatusService = (params: any) => ApiInstance.put(`${api.ACCOUNT_STATUS}`, params); // block/unblock api

// 'GET_USER_PROFILE': setting.api.url + 'user/get/profile'

// Get category and sub cat api
export const getCategoryfilter = (params: any) => ApiInstance.get(`${api.GET_CATEGORY}?type=${params}`);
export const getSubCategoryfilter = (params: any) => ApiInstance.get(`${api.GET_SUB_CATEGORY}?categoryId=${params}`);

// @ts-expect-error TS(2554): Expected 1-2 arguments, but got 0.
export const getAdminArticleList = (params: any) => ApiInstance.get();
// Proposal api

export const getAdminProposalService = (params: any) => ApiInstance.get(`${api.GET_ADMIN_PROPOSAL}/${params}`);

export const searchAdminProposalService = (params: any) =>
  ApiInstance.get(
    `${api.SEARCH_ADMIN_PROPOSAL}?name=${params.string}&status=${params.status}&limit=${params.limit}&page=${params.page}`,
  );

export const getViewAdminProposalService = (params: any) => ApiInstance.get(`${api.GET_VIEW_ADMIN_PROPOSAL}/${params}`);

// Services api
export const getUserService = (params: any) =>
  ApiInstance.get(
    `${api.GET_USER_SERVICE}?page=${params.page}&limit=${params.limit}&searchString=${params.searchString}&status=${params.status}&category=${params.category}&subCategory=${params.subCategory}&talentId=${params.talentId}`,
  );
export const getUserServiceDetails = (params: any) => ApiInstance.get(`${api.GET_USER_SERVICE_DETAILS}?serviceId=${params}`);
export const updateServicesStatus = (params: any) => ApiInstance.post(`${api.SERVICE_STATUS_UPDATE}/${params.status}`, params);
export const multiDeleteService = (params: any) => ApiInstance.post(`${api.MULTI_DELETE_SERVICES}`, params);

// Image upload
export const imageUpload = (params: any) => ApiInstance.post(`${api.IMAGE_UPLPOAD}`, params);

// Articles
export const postAdminArticle = (params: any) => ApiInstance.post(`${api.ADMIN_ADD_ARTICLE}`, params);
export const listAdminArticle = (params: any) =>
  ApiInstance.get(
    `${api.ADMIN_LIST_ARTICLE}?status=${params.status}&type=${params.type}&category=${params.category}&subCategory=${params.subCategory}&page=${params.page}&limit=${params.limit}&searchString=${params.searchString}&skills=${params.skills}`,
  );
export const articleDelete = (params: any) => ApiInstance.put(`${api.ARTICLE_DELETE}`, params);
export const updateArticleStatus = (params: any) => ApiInstance.put(`${api.ADMIN_STATUS_UPDATE}`, params);
export const getArticleDetails = (params: any) => ApiInstance.get(`${api.GET_ARTICLE_DETAILS}/${params}`);
export const editArticle = (params: any) => ApiInstance.put(`${api.UPDATE_ARTICLE}`, params);
export const getSkills = (params: any) =>
  ApiInstance.get(`${api.GET_SKILLS}?categoryId=${params.category}&subCategoryId=${params.subCategory}`);

export const adminChangePasswordService = (params: any, token: any) => {
  axios.defaults.headers.common['accessToken'] = `${token}`;
  axios.defaults.headers.common['authorization'] = `${settings.api.AUTH}`;
  return axios.put(api.ADMIN_CHANGE_PASSWORD, params);
};

//Blog

export const addBlogService = (params: any) => ApiInstance.post(`${api.ADD_BLOGS}`, params);

export const getBlogService = (params: any) =>
  ApiInstance.get(
    `${api.GET_BLOGS}?status=${params.status}&type=${params.type}&page=${params.page}&limit=${params.limit}&searchString=${params.searchString}`,
  );

export const blogStatusHandleService = (params: any) => ApiInstance.post(`${api.BLOGS_STATUS}/${params?.status}`, params?.data);

export const getBlogDetailService = (params: any) => ApiInstance.get(`${api.GET_BLOG_DETAIL}/${params}`);

export const updateBlogService = (params: any) => ApiInstance.put(`${api.UPDATE_BLOGS}`, params);

// Help and support
export const showContactUs = (params: any) =>
  ApiInstance.get(
    `${api.CONTACT_US}?status=${params.status}&type=${params.type}&page=${params.page}&limit=${params.limit}&searchString=${params.searchString}`,
  );
export const replyContactUs = (params: any) => ApiInstance.put(`${api.REPLY_CONTACT_US}`, params);
export const viewContactDetails = (params: any) => ApiInstance.get(`${api.VIEW_CONTACT_US}?queryId=${params}`);

// Portfolio
export const getPortfolioDetails = (params: any) => ApiInstance.get(`${api.VIEW_PORTFOLIO}?portfolioId=${params}`);
export const getPortfolio = (params: any) =>
  ApiInstance.get(
    `${api.GET_PORTFOLIO}?status=${params.status}&page=${params.page}&limit=${params.limit}&searchString=${params.searchString}&categoryId=${params.category}`,
  );
export const updatePortfolioStatus = (params: any) => ApiInstance.post(`${api.PORTFOLIO_STATUS_UPDATE}/${params.status}`, params);

//Dispute
export const getDisputeListService = (params: any) =>
  ApiInstance.get(
    `${api.GET_DISPUTE_LIST}?status=${params.status}&page=${params.page}&limit=${params.limit}&searchString=${params.searchString}&disputeBy=${params.disputeBy}&disputeWith=${params.disputeWith}`,
  );

export const getDisputeService = (params: any) => ApiInstance.get(`${api.GET_DISPUTE}?disputeId=${params}`);

export const updateDisputeService = (params: any) => ApiInstance.put(`${api.UPDATE_DISPUTE}/${params.status}`, params.data);

//DOBS
export const getDobsListService = (params: any) =>
  ApiInstance.get(`${api.GET_DOBS_LIST}?page=${params.page}&limit=${params.limit}&name=${params.searchString}`);

// export const getUserProfileService = params=> ApiInstance.get(`${api.GET_DOBS_LIST}?&page=${params.page}&limit=${params.limit}&name=${params.searchString}`);

export const getDobsTransactionsListService = (params: any) =>
  ApiInstance.get(
    `${api.GET_DOBS_TRANSACTIONS_LIST}?userId=${params.userId}&page=${params.page}&limit=${params.limit}&days=${params.days}`,
  );

// export const _getDobsTransactionsListService = params=> ApiInstance.get(`${api.GET_DOBS_TRANSACTION_LIST}?userId=${params.userId}&page=${params.page}&limit=${params.limit}&days=${params.days}`);

export const getTransactionsListService = (params: any) =>
  ApiInstance.get(
    `${api.GET_TRANSACTION_LIST}?page=${params.page}&limit=${params.limit}&search=${params.searchString}&status=${params.status}`,
  );

export const _getDobsTransactionsListService = (params: any) =>
  ApiInstance.get(
    `${api.GET_DOBS_TRANSACTION_LIST}?page=${params.page}&limit=${params.limit}&searchString=${params.searchString}&type=${params.type}`,
  );

export const getTransactionDetailService = (params: any) => ApiInstance.get(`${api.GET_TRANSACTION_DETAIL}?paymentId=${params}`);

export const getDobDetailService = (params: any) =>
  ApiInstance.get(`${api.GET_DOBS_DETAILS}?userId=${params}&tansactionId=${params.userId}`);

export const getTransactionHistoryListService = (params: any) =>
  ApiInstance.get(
    `${api.GET_TRANSACTION_HISTORY_LIST}?limit=${params.limit}&year=${params.year}&page=${params.page}&month=${params.month}&searchString=${params.searchString}`,
  );

export const getTransactionHistoryDetailService = (params: any) =>
  ApiInstance.get(`${api.GET_TRANSACTION_HISTORY_DETAIL}/${params}`);

//PromoCode
export const addPrmocodeService = (params: any) => ApiInstance.post(`${api.ADD_PROMOCODE}`, params);

export const getCouponListService = (params: any) =>
  ApiInstance.get(
    `${api.GET_LIST_PROMOCODE}?type=${params.type}&page=${params.page}&limit=${params.limit}&name=${params.searchString}`,
  );

export const getCouponDetailsService = (params: any) => ApiInstance.get(`${api.GET_PROMOCODE}?couponCode=${params}`);

export const updatePromoService = (params: any) =>
  ApiInstance.put(
    `${api.UPDATE_PROMOCODE}?couponCode=${params.CouponCode}&maxUsages=${params.maxUsages}&description=${params.description}&value=${params.value}`,
  );

export const deletePrmocodeService = (params: any) => ApiInstance.delete(`${api.DELETE_PROMOCODE}?id=${params}`);

// testimonial and reviews
export const getAllTestimonial = (params: any) =>
  ApiInstance.get(
    `${api.GET_TESTIMONIAL}?status=${params.status}&page=${params.page}&limit=${params.limit}&searchString=${params.searchString}`,
  );
export const getClientReview = (params: any) =>
  ApiInstance.get(
    `${api.GET_CLIENT_REVIEW}?page=${params.page}&limit=${params.limit}&searchString=${params.searchString}&status=${params.status}&type=${params.type}&rating=${params.rating}&sort=${params.sort}`,
  );
export const updatePublicReviewStatus = (params: any) =>
  ApiInstance.put(`${api.PUBLISH_PUBLIC_REVIEW}?id=${params.id}&status=${params.status}`);
export const updateClientReviewStatus = (params: any) =>
  ApiInstance.put(`${api.PUBLISH_CLIENT_REVIEW}?reviewId=${params.reviewId}&status=${params.status}`);
export const deleteClientReview = (params: any) => ApiInstance.post(`${api.DELETE_CLIENT_REVIEW}`, params);
export const getClientReviewDetails = (params: any) =>
  ApiInstance.get(`${api.VIEW_DETAILS_OF_CLIENT_REVIEW}?userId=${params.id}&rating=${params.rating}`);
export const getClientReviewDetailsbyId = (params: any) => ApiInstance.get(`${api.GET_CLIENT_REVIEW_BY_ID}?reviewId=${params}`);
export const deletePublicReview = (params: any) => ApiInstance.post(`${api.DELETE_PUBLIC_TESTIMONIAL}`, params);
export const getPublicReviewDetailsbyId = (params: any) =>
  ApiInstance.get(`${api.VIEW_DETAILS_OF_PUBLIC_REVIEW}?testimonialId=${params}`);
export const getTopTalentListAll = (params: any) =>
  ApiInstance.get(
    `${api.GET_ALL_TOP_TALENT_LIST}?page=${params.page}&limit=${params.limit}&searchString=${params.searchString}&project=${params.sort}&rating=${params.rating}&status=${params.status}&categoryId=${params.category}&subCategoryId=${params.subCategory}&skillId=${params.skills}&month=${params.month}&year=${params.year}`,
  );
export const statusTopTalentChange = (params: any) =>
  ApiInstance.put(
    `${api.STATUS_CHANGE_FOR_TOP_TALENT}?id=${params.id}&status=${params.status}&month=${params.month}&year=${params.year}`,
  );
export const getTopTalentDetails = (params: any) => ApiInstance.get(`${api.GET_TOP_TALENT_DETAILS}?userId=${params}`);
export const deleteReply = (params: any) =>
  ApiInstance.delete(`${api.DELETE_REPLY}?replyId=${params.replyId}&reviewId=${params.reviewId}`);

// ads api
export const addAds = (params: any) => ApiInstance.post(`${api.ADD_ADS}`, params);
export const getAllAds = (params: any) =>
  ApiInstance.get(
    `${api.GET_ALL_ADS}?page=${params.page}&limit=${params.limit}&title=${params.searchString}&status=${params.status}&type=${params.type}&state=${params.state}`,
  );
export const deleteSingleAds = (params: any) => ApiInstance.post(`${api.DELETE_ADS_SINGLE}`, params);
export const updateAdsDetails = (params: any) =>
  ApiInstance.put(
    `${api.UPDATE_ADS}?adsId=${params.adsId}&status=${params.status}&title=${params.title}&link=${params.link}&image=${params.image}&description=${params.description}`,
  );

//DashBoard
export const getDashboardDetails = (params: any) => ApiInstance.get(`${api.GET_DASHBOARD_DETAILS}`);

export const getAdsDetails = (params: any) => ApiInstance.get(`${api.GET_SINGLE_AD}?adsId=${params}`);

export const deleteAllService = (params: any) => ApiInstance.post(`${api.MULTIPLE_DELETE}`, params);

// payment request
export const getPaymentRequestList = (params: any) =>
  ApiInstance.get(
    `${api.GET_PAYMENT_REQUEST_LIST}?&status=${params.status}&page=${params.page}&limit=${params.limit}&requestId=${params.searchString}&userId=${params.talentId}`,
  );
export const getPaymentRequestDetails = (params: any) => ApiInstance.get(`${api.GET_SINGLE_PAYMENT_REQUEST}?requestId=${params}`);
export const rejectPaymentRequest = (params: any) => ApiInstance.put(`${api.REJECT_PAYMENT_REQUEST}`, params);
export const payoutPayment = (params: any) => ApiInstance.post(`${api.PAYOUT_PAYMENT}`, params);
export const payoutPaymentPayoneer = (params: any) => ApiInstance.post(`${api.PAYOUT_PAYMENT_PAYONEER}`, params);

// Service Charge
export const getServiceChargeAll = (params: any) =>
  ApiInstance.get(
    `${api.GET_ALL_SERVICE_CHARGE}?&status=${params.type}&page=${params.page}&limit=${params.limit}&searchString=${params.searchString}`,
  );
export const addServiceCharge = (params: any) => ApiInstance.post(`${api.ADD_SERVICE_CHARGE}`, params);
export const getServiceChargeDetails = (params: any) => ApiInstance.get(`${api.SERVICE_CHARGE_DETAILS_VIEW}?Id=${params}`);
export const updateServiceCharge = (params: any) => ApiInstance.put(`${api.UPDATE_SERVICE_CHARGE}`, params);
export const deleteServiceCharge = (params: any) => ApiInstance.delete(`${api.DELETE_SERVICE_CHARGE}?Id=${params}`);

//FAQs
export const addFAQs = (params: any) => ApiInstance.post(`${api.ADD_FAQs}`, params);

export const getFAQs = (params: any) =>
  ApiInstance.get(
    `${api.LIST_FAQs}?page=${params.page}&limit=${params.limit}&type=${params.type}&searchString=${params.searchString}`,
  );

export const updateFAQs = (params: any) => ApiInstance.put(`${api.UPDATE_FAQs}?faqId=${params.faqId}`, params);

export const deleteFAQs = (params: any) => ApiInstance.post(`${api.DELETE_FAQs}`, params);

export const getDetailFAQs = (params: any) => ApiInstance.get(`${api.GET_FAQs}?faqId=${params}`);

// Email Lists
export const getEmailLists = () => ApiInstance.get(`${api.GET_EMAIL_LIST}`);
export const getEmailsFromList = (id: any, params: any) =>
  ApiInstance.get(`${api.GET_EMAILS_FROM_LIST}${id}`, { params: params });
export const editEmailListItem = (params: any) => ApiInstance.post(`${api.EDIT_EMAIL_LIST_ITEM}`, params);
export const approveUserOnWaitlist = (params: any) => ApiInstance.post(`${api.APPROVE_USER_ON_WAITLIST}`, params);


// Freepik
export const getFreePikPlanInfo = (): Promise<ResponseBaseType<GetFreePikPlanResponse>> =>
  ApiInstance.get(`${api.GET_FREEPIK_PLAN_INFO}`);

// Homepage
export const getHomepageProfessionals = (): Promise<ResponseBaseType<GetProfessionalsResponse>> =>
  ApiInstance.get(`${setting.api.url2}homepage/get-professionals`);

export const addHomepageProfessional = (params: AddProfessionalRequest): Promise<ResponseBaseType<GetProfessionalsResponse>> =>
  ApiInstance.post(`${setting.api.url2}homepage/add-professional`, params);

export const removeHomepageProfessional = (
  params: RemoveProfessionalRequest,
): Promise<ResponseBaseType<GetProfessionalsResponse>> =>
  ApiInstance.post(`${setting.api.url2}homepage/remove-professional`, params);
