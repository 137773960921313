import React, { Component, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PrivateRoutes from './routing/PrivateRoutes';
import './scss/style.scss';
import './App.css';
import Dashboard from './views/dashboard/Dashboard';
import PublicRoute from './routing/PublicRoutes';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Home = React.lazy(() => import('./views/home/Home'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const ForgetPassword = React.lazy(() => import('./views/pages/forgetPassword/ForgetPassword'));
const ResetPassword = React.lazy(() => import('./views/pages/resetPassword/ResetPassword'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
  const auth = localStorage.getItem('accessToken');
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          {/* @ts-expect-error TS(2322): Type '{ exact: true; path: string; name: string; e... Remove this comment to see the full error message */}
          <Route exact path="/login" name="Login Page" element={<PublicRoute Component={Login} />} />

          {/* @ts-expect-error TS(2322): Type '{ exact: true; path: string; name: string; e... Remove this comment to see the full error message */}
          <Route exact path="/forget-password" name="Register Page" element={<PublicRoute Component={ForgetPassword} />} />
          <Route
            // @ts-expect-error TS(2322): Type '{ exact: true; path: string; name: string; e... Remove this comment to see the full error message
            exact
            path="/reset-password/:accessToken"
            name="Reset Password"
            element={<PublicRoute Component={ResetPassword} />}
          />
          {/* @ts-expect-error TS(2322): Type '{ exact: true; path: string; name: string; e... Remove this comment to see the full error message */}
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          {/* @ts-expect-error TS(2322): Type '{ path: string; name: string; element: Eleme... Remove this comment to see the full error message */}
          <Route path="*" name="Home and Dashboard" element={<PrivateRoutes Component={DefaultLayout} />} />
        </Routes>
        <ToastContainer />
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
