const iState = {
  cmsData: {},
};
export const cmsReducer = (state = iState, action: any) => {
  switch (action.type) {
    case 'ADD_CMS':
      return {
        ...state,
        addCms: action.payload,
      };
    case 'UPDATE_CMS':
      return {
        ...state,
        updateCms: action.payload,
        isUpdated: 'yes',
      };
    default:
      return state;
  }
};
