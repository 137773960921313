const iState = {
  userData: {},
};
export const loginReducer = (state = iState, action: any) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.payload,
        mfaRequired: false,
        loading: false,
      };
    case 'MFA_REQUIRED':
      return {
        ...state,
        mfaRequired: action.payload,
        loading: false,
      };
    case 'FORGET-PASWORD':
      return {
        ...state,
        userData: action.payload,
      };
    case 'RESET-PASWORD':
      return {
        ...state,
        userData: action.payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        // @ts-expect-error TS(2339): Property 'user' does not exist on type '{ userData... Remove this comment to see the full error message
        userData: iState.user,
      };
    default:
      return state;
  }
};
