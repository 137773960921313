import { development, local, staging } from './env';

export default (() => {
  console.log(process.env.REACT_APP_ENV, '<<<<<<<===========REACT_APP_ENV===============>>>>>>>');
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
    case 'dev ':
    case 'development':
      return development;

    case 'stag':
    case 'stag ':
    case 'staging':
      return staging;

    case 'prod':
    case 'prod ':
    case 'production':
      return development;

    case 'local':
    case 'local ':
      return local;

    default:
      console.log('development reruning');
      return local;
  }
})();
