import { combineReducers } from 'redux';
import { addCms } from 'src/actions/cmsActions';
import { addCategoryReducer } from './addCategoryReducer';
import { cmsReducer } from './cmsReducers';
import { loginReducer } from './loginReducer';
import { changeState } from './sideBarReducer';
import { articleReducer } from './articleReducer';

const rootReducer = combineReducers({
  nav: changeState,
  userDetails: loginReducer,
  profileCategory: addCategoryReducer,
  cmsDetails: cmsReducer,
  articleDetails: articleReducer,
});

export default rootReducer;
