import { useEffect } from 'react';
import { Outlet, Navigate, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { tokenVarificationService } from 'src/service';

const PublicRoute = (props: any) => {
  const params = useParams();
  const _resetPassToken = params.accessToken;

  const navigate = useNavigate();

  const { Component } = props;
  let auth = localStorage.getItem('accessToken');

  useEffect(() => {
    if (_resetPassToken) {
      localStorage.setItem('urlToken', _resetPassToken);

      tokenVarificationService(_resetPassToken).then((res: any) => {
        if (res.statusCode === 0 && res.errors[0].errorCode === 4) {
          toast.error('Link has been expired', {
            autoClose: 1500,
            closeOnClick: true,
            hideProgressBar: true,
            pauseOnHover: false,
          });
          navigate('/login');
        }
      });
    }
  }, [_resetPassToken]);

  return !auth ? <Component /> : <Navigate to="/" />;
};

export default PublicRoute;
