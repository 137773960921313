import { useEffect } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import Dashboard from 'src/views/dashboard/Dashboard';

const PrivateRoutes = (props: any) => {
  const navigate = useNavigate();

  const { Component } = props;
  let auth = localStorage.getItem('accessToken');

  return auth ? <Component /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
