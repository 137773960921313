export const development = {
  api: {
    url: 'https://devapi.dobrh.com/api/v1/',
    url2: 'https://devapi.dobrh.com/api/v2/',
    AUTH: 'Basic ZG9icmhkZXY6RDBiUmhAIQ==',
  },
};

export const staging = {
  api: {
    url: 'https://stagapi.dobrh.com/api/v1/',
    url2: 'https://stagapi.dobrh.com/api/v2/',
    AUTH: 'Basic ZG9icmhzdGFnOkQwYlJoQCFzVGFn',
  },
};

export const local = {
  api: {
    url: 'http://localhost:5001/api/v1/',
    url2: 'http://localhost:5001/api/v2/',
    AUTH: 'Basic ZGVtbzpkZW1v',
  },
};

// export const prod = {
//     api: {
//         url: process.env.REACT_APP_STAG_BASE_URL,
//         url2: process.env.REACT_APP_STAG_BASE_URL,
//         AUTH: process.env.REACT_APP_BASIC_AUTH,
//     },
// }
