const iState = {
  articleData: {
    title: '',
    category: '',
    subCategory: '',
    skills: [],
    image: '',
    editor: '',
  },
};

export const articleReducer = (state = iState, action: any) => {
  switch (action.type) {
    case 'title':
      return {
        ...state,
        articleData: action.payload,
      };

    // case 'category':
    //   return {
    //     ...state,
    //     articleData: action.payload
    //   }
    // case 'subcategory':
    //   return {
    //     ...state,
    //     articleData: action.payload
    //   }
    // case 'skills':
    //   return {
    //     ...state,
    //     articleData: action.payload
    //   }
    // case 'image':
    //   return {
    //     ...state,
    //     articleData: action.payload
    //   }
    // case 'editor':
    //   return {
    //     ...state,
    //     articleData: action.payload
    //   }

    default:
      return state;
  }
};
