import axios from 'axios';
import { Navigate } from 'react-router-dom';

import settings from '../config/settings';

const ApiInstance = axios.create({
  baseURL: settings.api.url,
});

ApiInstance.interceptors.request.use(
  (req) => {
    let token = localStorage.getItem('accessToken');
    let headers = {
      'Content-Type': 'application/json',
    };

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    headers['authorization'] = settings.api.AUTH;
    // headers["accessToken"] = token;
    headers = {
      ...headers,
      ...(token && { accessToken: token }),
    };

    req.headers = headers;

    return req;
  },
  (error) => Promise.reject(error),
);

ApiInstance.interceptors.response.use(
  (res) => {
    return {
      statusCode: res.data.statusCode,
      data: res.data.responseData,
    };
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear();
        return Navigate({ to: '/login' });
      }

      // ensure `errors` is an array for backwards compatibility  
      if (error.response.data?.error instanceof Array) {
        return {
          statusCode: 0,
          errors: error.response.data?.error,
        };
      } else {
        return {
          statusCode: 0,
          errors: [error.response.data?.error],
        };
      }
    }

    if (error.message === 'Network Error') {
      const networkError = {
        errorCodeList: ['NETWORK'],
      };

      return Promise.reject(networkError);
    } else if (error.message === `timeout of 3 ms exceeded`) {
      const timeoutError = {
        errorCodeList: ['TIMEOUT'],
      };

      return Promise.reject(timeoutError);
    }

    return Promise.reject(error);
  },
);

export default ApiInstance;
